(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@vanilla-extract/dynamic"), require("react"), require("react-fast-compare"), require("react-router-dom"), require("react-transition-group"));
	else if(typeof define === 'function' && define.amd)
		define(["@vanilla-extract/dynamic", "react", "react-fast-compare", "react-router-dom", "react-transition-group"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@vanilla-extract/dynamic"), require("react"), require("react-fast-compare"), require("react-router-dom"), require("react-transition-group")) : factory(root["@vanilla-extract/dynamic"], root["react"], root["react-fast-compare"], root["react-router-dom"], root["react-transition-group"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__479__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__128__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 